$cancelBG: #767679;
$cancelBGHover: #545458;

.ProviderSearch__dialog .MuiDialogContent-root {
    .send-email__content {
        max-width: 840px;
        width: 100%;

        .fieldgroup__container .fieldgroup__field {
            textarea.fieldgroup__field-input {
                border-radius: 0;
                box-shadow: none;
                background: none;
                padding: 10px 15px;
                height: auto;
                font-weight: 400;

                &::placeholder {
                    color: $cancelBG;
                }
            }

            .MuiFormControl-root {
                padding: 0px;

                .MuiInputBase-root {
                    input {
                        background-color: inherit;
                        box-shadow: none;
                        border: none;
                        font-weight: 400;
                        height: auto;
                        height: 60px;
                        padding: 0px 20px;
                        font-size: x-large;
                    }

                    textarea {
                        box-shadow: none;
                        padding: 8px;
                        font-size: x-large;
                    }

                    textarea:focus {
                        background-color: inherit;
                        border: none;
                    }
                }

                .MuiInput-root::after {
                    border: none;
                }

                .MuiInput-root::before {
                    border: none;
                }
            }
        }
    }

    .send-email__footer {
        display: flex;
        justify-content: space-between;

        button {
            width: 48%;

            &.cancel,&.Mui-disabled {
                background: $cancelBG;

                &:hover {
                    background: $cancelBGHover;
                }
            }
        }
    }
}
