.typing__container {
    font-size: 24px;
    display: flex;
    padding: 0 30px;
    color: #727272;
    width: 270px;

    .typing__loader {
        width: 12px;
        height: 12px;
        border-radius: 50%;
        display: block;
        margin:15px auto;
        position: relative;
        color: #727272;
        transform: scale(.35);
        box-sizing: border-box;
        animation: animloader 1s linear infinite alternate;
      }
      
      @keyframes animloader {
        0% {
          box-shadow: -38px -6px, -14px 6px,  14px -6px;
        }
        33% {
          box-shadow: -38px 6px, -14px -6px,  14px 6px;
        }
        66% {
          box-shadow: -38px -6px, -14px 6px, 14px -6px;
        }
        100% {
          box-shadow: -38px 6px, -14px -6px, 14px 6px;
        }
    }
}