.collapsable-section__container {
    .collapsable-section__header {
        display: flex;
        align-items: baseline;
        margin-bottom: 20px;
        cursor: pointer;

        .collapsable-section__header-title {
            font-size: 32px;
            line-height: 40px;
            font-family: "Gotham HTF", sans-serif;
            font-weight: 600;
        }

        .collapsable-section__header-icon {
            margin-left: 10px;
            font-size: 26px;
        }
    }

    .collapsable-section__content {
        display: none;
        opacity: 0;
        height: 0;

        p {
            font-size: 26px;
            line-height: 40px;
            font-family: "Gotham HTF", sans-serif;
            margin-top: 50px;
        }
    }

    &.with-margin {
        margin-top: 50px;
    }

    &.open {
        .collapsable-section__content {
            display: block;
            opacity: 1;
            height: auto;
        }

        .collapsable-section__header-icon {
            transform: rotate(180deg);
        }
    }
}

@media (max-width: 1024px) {
    .collapsable-section__container {
        .collapsable-section__header {
            align-items: center;

            .collapsable-section__header-title {
                font-size: 26px;
                line-height: 36px;
            }
        }

        .collapsable-section__content {
            p {
                margin-top: 30px;
                font-size: 24px;
                line-height: 36px;
            }
        }
    }
}
