$fontColor: #fff;
$backGroundColor: #1b54c2;

.resultActions__OuterContainer {
    width: 100%;
    height: 80px;
    background-color: $backGroundColor;
    box-sizing: border-box;

    .resultActions__InnerContainer {
        max-width: 1320px;
        width: calc(100% - 100px);
        margin-left: auto;
        margin-right: auto;
        height: 100%;
        color: $fontColor;
        display: flex;
        box-sizing: border-box;
        padding: 30px 0;
        align-items: center;
        justify-content: space-between;
        font-size: 24px;
        font-family: "franklin-gothic-urw", sans-serif;

        .resultActions__section {
            display: flex;
            align-items: center;

            strong {
                margin-right: 5px;
            }

            .icon__container {
                margin-right: 20px;
                display: flex;
                align-items: center;

                button {
                    color: $fontColor;
                    text-transform: capitalize;
                    font: normal normal 400 24px "franklin-gothic-urw", sans-serif;
                    height: 30px;
                }

                svg {
                    margin-right: 5px;
                    margin-bottom: 3px;
                    width: 24px;
                    height: 30px;

                    path {
                        fill: white;
                    }
                }
            }

            span:nth-child(2) {
                margin-right: 10px;

                svg {
                    margin-bottom: 0px;
                }
            }

            .mobile__hide {
                white-space: nowrap;
                font-family: "franklin-gothic-urw", sans-serif;
                font-weight: 400;
            }

            .result__filters {
                width: auto;
                padding-left: 10px;
                border-bottom: 1px solid $fontColor;
            }

            .resultActions__spinner .MuiCircularProgress-indeterminate {
                color: $fontColor;
            }
        }

        .result__filters .MuiInputBase-root {
            height: 35px;
            width: 160px;
            border: 0;
            border-radius: 0;

            // !important should be used as a last resource.
            // here is needed to avoid to overwrite each one of the mui styles
            padding-right: 0 !important;

            .MuiSelect-select {
                padding-left: 10px;
                line-height: 60px;
            }

            input, .MuiSelect-select {
                color: $fontColor !important;
                font: normal normal 700 24px "franklin-gothic-urw", sans-serif;
                background: none;
                padding: 0;
                box-shadow: none;
                border: 0;
                margin-bottom: -8px;

                &::placeholder {
                    color: $fontColor !important;
                }

                &:focus {
                    box-shadow: none;
                    border: none;
                }
            }

            fieldset {
                border: none;
            }

            .MuiSvgIcon-root {
                color: $fontColor;
            }
        }
    }
}

@media (max-width: 1280px) {
    .resultActions__OuterContainer {
        height: 160px;

        .resultActions__InnerContainer {
            flex-direction: column;
            width: 100%;
            padding: 0;

            .resultActions__section {
                width: 100%;
                border-bottom: 1px solid #fff;
                padding-bottom: 10px;
                height: 80px;
                display: flex;
                justify-content: center;
            }
        }
    }
}

@media (max-width: 1024px) {
    .resultActions__OuterContainer {
        .resultActions__InnerContainer {
            font-size: 20px;

            .resultActions__section {
                padding: 0 10px;

                .icon__container {
                    margin-right: 10px;

                    button {
                        font-size: 20px;
                    }
                }

                span:nth-child(2) {
                    margin-right: 0;
                }
            }

            .result__filters {
                .MuiInputBase-root {
                    input, .MuiSelect-select {
                        font-size: 20px;
                    }
                }
            }
        }

        .mobile__hide {
            display: none;
        }
    }
}
