$backColorBase: #ffffff;
$colorBase: #000000;
$checkColor: #76bc21;
$exclamationColor: #f9a127;
$tackBackground: #dedede;
$thumbColor: #0b3a5d;
$closeButtonColor: #1b54c2;

.label__base {
  text-align: left;
  font-weight: 600;
  font-size: 24px;
  font-family: "Gotham HTF", sans-serif;
  letter-spacing: 0px;
  color: $colorBase;
}

.text__base {
  text-align: left;
  font-size: 24px;
  letter-spacing: 0px;
  color: $colorBase;
}

hr.MuiDivider-root {
  margin-top: 20px;
  border-color: $tackBackground;
}

.medicalGroupPreview__container {
  height: 100%;
  width: 100%;
  padding: 25px;
  background-color: $backColorBase;
  border-radius: 50px;

  .header__container {
    display: flex;
    align-items: center;
  }
  .icon__container {
    display: flex;
    align-items: center;
    justify-content: center;
    color: $colorBase;

    .buttonIcon {
      color: $closeButtonColor;
      cursor: pointer;
      background: transparent;
      border: none;
    }

    .groups__length {
      font-size: 30px;
      font-weight: bold;
      margin-left: 10px;
    }

    &.addIcon__container {
      background: transparent;
      width: 84px;
      border-radius: 10px;
      color: $colorBase;

      svg {
        height: 34px;
        width: 34px;
      }
    }
  }
  .title__container {
    flex: 1;
    text-align: left;
    font-size: 36px;
    font-weight: 600;
    line-height: 34px;
    letter-spacing: 0px;
    color: $colorBase;
    text-transform: capitalize;
    opacity: 1;
  }

  .medicalGroupList__container {
    overflow-y: auto;
    max-height: 350px;
    margin-top: 15px;

    &::-webkit-scrollbar {
      width: 0.4em;
    }

    &::-webkit-scrollbar-track {
      background-color: $tackBackground;
      border-radius: 10px;
    }

    &::-webkit-scrollbar-thumb {
      background-color: $thumbColor;
      outline: 1px solid $thumbColor;
      border-radius: 10px;
    }

    .medicalGroupList {
      list-style-type: none;
      margin: 0px 85px 0px 85px;
      padding: 0;

      .medicalGroupDetail__container {
        .groupName__container {
          @extend .label__base;
        }

        .groupId__container {
          .groupProviderId__label {
            @extend .label__base;
          }
          .groupProviderId__text {
            @extend .text__base;
          }
        }

        .groupPCP__container {
          .groupProviderPCP__label {
            @extend .label__base;
          }
          .groupProviderPCP__text {
            @extend .text__base;
          }
        }

        .groupAcceptingConditions__container {
          margin-top: 10px;

          .provider__labels {
            font-size: 22px;

            .check__icon {
              color: $checkColor;
              margin-right: 5px;
            }
            .exclamation__icon {
              color: $exclamationColor;
              margin-right: 5px;
            }
            .icon__container {
              margin-bottom: 0px !important;
              justify-content: flex-start !important;
              width: 100%;
              height: auto;
            }
          }
        }
      }
    }
  }
}

@media (max-width: 575px) {
  .medicalGroupPreview__container {
    .header__container {
      flex-direction: row;
      align-items: flex-start;
    }

    .medicalGroupList__container .medicalGroupList {
      margin: 0px 15px 0px 5px;
    }
  }
}