$linkFontColor: #1b54c2;

.findAProviderHeader {
    display: flex;
    flex-direction: column;
    align-items: center;
    font: normal normal 700 32px/33px "franklin-gothic-urw", sans-serif;
    text-align: center;
    padding: 0;

    h2 {
        font: normal normal 700 80px/73px "franklin-gothic-urw", sans-serif;
        letter-spacing: 0.59px;
        padding: 70px 0 40px;
        margin: 0;
    }

    .findAProviderHeader__help {
        padding: 0;
        text-decoration: underline;
        color: $linkFontColor;
        font: normal normal 600 24px/29px "franklin-gothic-urw", sans-serif;
        text-transform: none;
        background: none;
        box-shadow: none;
    }
}

@media (max-width: 1024px) {
    .findAProviderHeader {
        text-align: left;

        h2 {
            font-size: 36px;
            margin: 10px auto 10px;
            padding: 20px 0 20px
        }

        .findAProviderHeader__button {
            position: unset;
            bottom: 0;
            left: 0;
            margin-bottom: 7px;
        }
    }
}
