$headerFontColor: #000;
$detailFontColor: #f8f8f8;
$ghostWhite: #f5f5f8;
$iconColor: #1b54c2;

.PCP_details__div {
    padding-top: 45px;
    background: $ghostWhite 0% 0% no-repeat padding-box;
    font-size: 22px;
    font-family: "Gotham HTF", sans-serif;
    line-height: 24px;
    opacity: 1;
    width: 100%;

    .PCP_details__container {
        width: 100%;
        display: flex;
        justify-content: space-between;
        box-sizing: border-box;
        max-width: 75rem;
        margin-left: auto;
        margin-right: auto;

        .PCP_details__name {
            padding-bottom: 32px;
            text-align: left;
            font-size: 44px;
            line-height: 40px;
            letter-spacing: 0px;
            color: $headerFontColor;
            opacity: 1;
            word-break: break-word;

            .innerProvider {
                display: flex;
                flex-direction: row;
                justify-content: space-between;

                .provider__badge {
                    margin-left: 9px;
                    margin-right: 15px;
                    display: inline-block;
                    width: 40px;

                    svg {
                        width: 100%;
                        height: 100%;
                    }
                }
            }
        }

        .PCP_details__text {
            text-align: left;
            letter-spacing: 0px;
            color: $headerFontColor;
            opacity: 1;
            font-size: 26px;
            line-height: 1.6;
            display: flex;
            flex-direction: column;

            p {
                margin: 0;
            }

            .icon__container {
                display: flex;
                flex-direction: row;
                align-items: center;

                .check__icon {
                  color: #76bc21;
                  margin-right: 5px;
                  margin-top: 2px;
                }
              }

            .icons-data {
                display: flex;
                align-items: center;
                margin-top: 40px;
                padding-bottom: 33px;
                justify-content: space-between;
                width: 235px;

                .icons-col__div {
                    display: flex;
                    flex-direction: column;
                    align-items: center;

                    div:last-child {
                        align-content: stretch;
                        text-align: center;
                        font: normal normal 400 27px/30px "franklin-gothic-urw", sans-serif;
                        color: $iconColor;
                    }

                    div:first-child {
                        display: flex;
                        flex-direction: row;

                        svg {
                            fill: $iconColor;
                            margin-right: 10px;
                            width: 30px;
                            height: 30px;
                        }

                        span {
                            text-align: center;
                            font: normal normal bold 29px/29px "franklin-gothic-urw", sans-serif;
                            letter-spacing: 0.26px;
                            color: $iconColor;
                            opacity: 1;
                        }
                    }
                }
            }

            .PCP_details__ava_hmo {
                margin-top: -8px;

                svg {
                    display: none;
                }

                .PCP_details__ava_hmo_not_offering {
                    color: #000;
                    font-weight: 700;
                }
            }
        }

        .mapCol {
            display: flex;
            justify-content: space-between;
            padding-bottom: 47px;
            flex-direction: column;
            width: 600px;

            div:first-child {
                width: 100%;
            }

            .hyperlink {
                text-decoration: underline;
                font: normal normal 400 24px/25px "franklin-gothic-urw", sans-serif;
                letter-spacing: 0px;
                color: $iconColor;
                align-self: end;
            }

            .pcp__map {
                height: 300px;
                margin-bottom: 69px;
            }
        }
    }
}

@media (max-width: 1366px) {
    .PCP_details__div {
        .PCP_details__container {
            width: calc(100% - 40px);

            .PCP_details__name {
                font-size: 36px;
            }
        }
    }
}

@media (max-width: 1024px) {
    .PCP_details__div {
        .PCP_details__container {
            flex-direction: column;

            .PCP_details__name {
                font-size: 30px;
                line-height: 28px;
            }

            .PCP_details__text {
                font-size: 26px;
                line-height: 30px;

                .icons-data {
                    justify-content: start;
                    margin-top: 27px;

                    .icons-col__div {
                        margin-right: 20px;
                    }
                }
            }

            .mapCol {
                width: 100%;
                padding-bottom: 32px;

                .hyperlink {
                    font-size: 20px;
                    width: 100%;
                }

                .pcp__map {
                    margin-bottom: 20px;
                }
            }
        }
    }
}