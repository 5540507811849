$iconsContainerBackground: #f5f8fd;
$borderColor: #dedede;
$borderShadowColor: #0003;
$fontColor: #1b54c2;

.providerCard__container {
    width: 100%;
    max-width: 635px;
    height: 475px;
    border: solid 1px $borderColor;
    box-shadow: 1px 1px 5px $borderShadowColor;

    /* This property can be inherited form the father component, for that reason I set it here */
    background-color: #fff;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    .icons__innerContainer {
        background-color: $iconsContainerBackground;
        width: 100%;
        height: 80px;
        display: flex;
        align-items: center;
        justify-content: space-around;
        padding: 0 25px;
        font-size: 20px;

        .icon__container {
            display: flex;
            align-content: center;
            align-items: center;
            flex-direction: column;
            margin-left: 15px;
            color: $fontColor;
            margin-bottom: 0;
            background: transparent;
            border: none;

            .icon__text__container {
                display: flex;
                flex-direction: row;
                align-content: center;
                justify-content: center;
                align-items: center;
                justify-content: end;
                padding: 0 25px;
                font-size: 20px;
                border-bottom-left-radius: 20px;
                border-bottom-right-radius: 20px;

                .text__next_icon {
                    font-size: 24px;
                    margin-bottom: unset;
                    margin-left: 10px;
                }

                svg {
                    width: 18px;
                    fill: $fontColor;
                }
            }

            p {
                margin-bottom: unset;
            }

            &:hover {
                cursor: pointer;
            }
        }
    }
}

@media (max-width: 1024px) {
    .providerCard__container {
        width: 100%;
        height: 486px;

        .information__innerContainer {
            .provider__name {
                font-size: 28px;
                line-height: 33px;
            }

            p {
                line-height: 24px;
                font-size: 22px;
            }
        }

        .icons__innerContainer {
            justify-content: center;

            .icon__container {
                font-size: 18px;
                margin: 0 5%;

                .icon__text__container {
                    padding: 0 15px;
                }
            }
        }
    }
}