$white: #fff;
$black: #000;
$blue: #1b54c2;
$shadow: #00000029;
$colorIconActive: #2157C9;

$colorDoctor: #0f99a8;
$bgDoctor: #e7f5f6;
$bgDoctorActive: #ccd9f1;

$colorFacilities: #f05022;
$bgFacilities: #feeee9;
$bgFacilitiesActive: #f1d3ce; 

$colorDental: #0376a8;
$bgDental: #e6f1f6;
$bgDentalActive: #d1dfea;

$colorVision: #78bd42;
$bgVision: #f2f8ec;
$bgVisionActive: #cdd9d1;

$colorServices: #f9a127;
$bgServices: #fef5e8;
$bgServicesActive: #fdeda0;

.providertypes {
    width: calc(100% - 100px);
    display: flex;
    justify-content: space-between;
    box-sizing: border-box;
    max-width: 1320px;
    padding-right: 5%;
    padding-left: 5%;
    background-color: $white;
    box-shadow: 0px 3px 6px $shadow;
    margin: 0px auto 40px;
    flex-direction: column;
    position: relative;

    .providertypes__title {
        color: $black;
        margin: 47px 0;
        display: flex;
        align-items: baseline;
        font: normal normal 700 28px/33px "franklin-gothic-urw", sans-serif;

        .providertypes__help {
            text-decoration: underline;
            text-transform: none;
            background: none;
            box-shadow: none;
            color: $blue;
            font: normal normal 400 28px/33px "franklin-gothic-urw", sans-serif;
            padding: 0;
            margin-left: 10px;
            cursor: pointer;

            &:hover {
                background: none;
            }
        }
    }

    .providertypes__bullets {
        display: flex;
        justify-content: space-between;

        .providertypes__bullet {
            width: 192px;
            display: flex;
            text-align: center;
            flex-direction: column;
            align-items: center;
            background: none;
            border: none;
            outline: none;

            div[class*="providertypes__bullet-icon"] {
                border-radius: 50%;
                border: 10px solid;
                border-color: $white;
                width: 162px;
                height: 162px;
                display: flex;
                justify-content: center;
                align-items: center;
                cursor: pointer;
                transition: all 0.5s ease;
            }

            .providertypes__bullet-icon--doctor {
                &.active {
                    svg #g_swoosh > * {
                        fill: $bgDoctorActive;
                    }

                    svg #g_icon > * {
                        fill: $colorIconActive
                    }
                }
            }

            .providertypes__bullet-icon--facilities {
                &.active {
                    svg #g_swoosh > * {
                        fill: $bgFacilitiesActive;
                    }

                    svg #g_icon > * {
                        fill: $colorIconActive
                    }
                }
            }

            .providertypes__bullet-icon--dental {
                &.active {
                    svg #g_swoosh > * {
                        fill: $bgDentalActive;
                    }

                    svg #g_icon > * {
                        fill: $colorIconActive
                    }
                }
            }

            .providertypes__bullet-icon--vision {
                &.active {
                    svg #g_swoosh > * {
                        fill: $bgVisionActive;
                    }

                    svg #g_icon > * {
                        fill: $colorIconActive
                    }
                }
            }

            .providertypes__bullet-icon--services {
               &.active {
                    svg #g_swoosh > * {
                        fill: $bgServicesActive;
                    }

                    svg #g_icon > * {
                        fill: $colorIconActive
                    }
                }
            }

            .providertypes__bullet-text {
                font: normal normal 400 28px/32px "franklin-gothic-urw", sans-serif;
                letter-spacing: 0px;
                margin-top: 10px;
                cursor: pointer;
    
                &.active {
                    font: normal normal 700 28px/32px "franklin-gothic-urw", sans-serif;
                    color: $blue;
                }
            }

            .providertypes__bullet-underline {
                width: inherit;
            }

            &:hover {
                font-weight: bold;
                color: $blue;
                
                .providertypes__bullet-icon--doctor {
                    svg #g_swoosh > * {
                        fill: $bgDoctorActive;
                    }

                    svg #g_icon > * {
                        fill: $colorIconActive
                    }
                }

                .providertypes__bullet-icon--facilities {
                    svg #g_swoosh > * {
                        fill: $bgFacilitiesActive;
                    }

                    svg #g_icon > * {
                        fill: $colorIconActive
                    }
                }
    
                .providertypes__bullet-icon--dental {
                    svg #g_swoosh > * {
                        fill: $bgDentalActive;
                    }

                    svg #g_icon > * {
                        fill: $colorIconActive
                    }
                }
    
                .providertypes__bullet-icon--vision {
                    svg #g_swoosh > * {
                        fill: $bgVisionActive;
                    }

                    svg #g_icon > * {
                        fill: $colorIconActive
                    }
                }
    
                .providertypes__bullet-icon--services {
                    svg #g_swoosh > * {
                        fill: $bgServicesActive;
                    }

                    svg #g_icon > * {
                        fill: $colorIconActive
                    }
                }
            }
        }
    }

    .providertypes__bullets-mobile {
        display: none;
    }
}

@media (max-width: 1136px) {
    .providertypes {
        .providertypes__bullets {
            .providertypes__bullet-text {
                font-size: 24px;
                line-height: 28px;
            }

            .providertypes__bullet {
                div[class*="providertypes__bullet-icon"] {
                    width: 150px;
                    height: 150px;
                }
            }
        }
    }
}

@media (max-width: 1024px) {
    .providertypes {
        padding: none;
        box-shadow: none;
        margin: 0 auto;
        width: 100%;
        border-radius: 0px;
        padding-bottom: 50px;

        .providertypes__bullets-mobile {
            display: block;
        }

        .providertypes__bullets {
            display: none;
        }

        .providertypes__title {
            flex-direction: column;
            margin-bottom: 15px;

            strong,
            .providertypes__help {
                font-size: 22px;
                line-height: 16px;
            }

            .providertypes__help {
                margin-left: 0;
                margin-top: 20px;
            }
        }
    }
}
