$backgroundColorMain: #f8f8fb;
$itemColor: #1b54c2;
$itemColorSecondary: #e5ecf8;
$boxShadowColor: #00000029;
$accordionTitleColor: #fff;
$accordionTitleColorSecondary: #000000;
$contentColor: #f8f8f8;
$iconColor: white;
$hyperlinkColor: #0376a8;

%accordion_item {
    box-sizing: border-box;
    padding: 0 30px;
    padding: 12px 30px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.accordion_background {
    background: $backgroundColorMain;
    box-shadow: 0px 3px 6px $boxShadowColor;
    opacity: 1;
    margin-bottom: 16px;

    .accordion_icon {
        font: normal normal 700 34px/25px "franklin-gothic-urw", sans-serif;
        transform: rotate(180deg);
        margin-left: 10px;
    }

    .accordion_icon__rotate {
        font: normal normal 700 34px/25px "franklin-gothic-urw", sans-serif;
    }

    .accordion_header {
        text-align: left;
        font-size: 28px;
        line-height: 30px;
        letter-spacing: 0px;
        opacity: 1;

        svg {
            fill: $iconColor;
            margin-right: 10px;
            width: 26px;
            height: 26px;
        }
    }

    .accordion_header-secondary {
        text-align: left;
        font-size: 24px;
        line-height: 23px;
        letter-spacing: 0px;
        opacity: 1;
    }

    .accordion_content {
        font: normal normal 700 28px/25px "franklin-gothic-urw", sans-serif;
        letter-spacing: 0px;
        opacity: 1;
        text-transform: capitalize;
        padding: 30px 30px 1px 30px;

        h4 {
            font: normal normal 700 26px/32px "franklin-gothic-urw", sans-serif;
        }

        .content {
            font: normal normal 400 26px/32px "franklin-gothic-urw", sans-serif;
            padding: 0px;

            .medicalgroups_item-title {
                font: normal normal 700 24px/27px "franklin-gothic-urw", sans-serif;
                margin: 50px 0 0;
            }
        }

        .hyperlink {
            text-align: left;
            text-decoration: underline;
            font-size: 26px;
            font-family: "franklin-gothic-urw", sans-serif;
            font-weight: 700;
            line-height: 32px;
            letter-spacing: 0px;
            color: $hyperlinkColor;
        }
    }

    .accordion_content_secondary {
        background: #fff;
    }

    .accordion_background_main {
        @extend %accordion_item;
        background: $itemColor;
        color: $accordionTitleColor;
    }

    .accordion_background_secondary {
        @extend %accordion_item;
        background: $itemColorSecondary;
        color: $accordionTitleColorSecondary;

        .accordion_content_secondary {
            background: #fff;
        }
    }

    .location-index__container {
        display: flex;
        align-items: center;

        .header-title__container {
            margin-right: 10px;
        }

        .icon-title__container {
            position: relative;
            font-size: 20px;
            display: flex;

            .location-index {
                font-size: 28px;
                font-weight: 900;
                position: absolute;
                left: 5px;
                top: -6px;
                color: $itemColor;
            }
        }
    }
}

@media (max-width: 1024px) {
    .accordion_background {
        .accordion_item {
            align-items: end;

            .accordion_header {
                font-size: 20px;
                line-height: 30px;
            }

            .accordion_icon,
            .accordion_icon__rotate {
                font-size: 24px;
            }
        }

        .accordion_content {

            h4,
            .hyperlink,
            .content {
                font-size: 22px;
                line-height: 28px;
            }

            .content {
                padding-bottom: 10px;
            }
        }
    }
}

@media (max-width: 575px) {
    .accordion_background {
        .accordion_content {
            padding: 30px 20px 1px 20px;
        }
    }
}