$cancelBG: #767679;
$cancelBGHover: #545458;
$grey: #767679;

.ProviderSearch__dialog .MuiDialogContent-root {
    .get-directions__content {
        width: 100%;
        height: auto;
        padding-top: 2px;

        .get-directions__map {
            width: 100%;
            height: 300px;
            margin-left: auto;
            margin-right: auto;
        }

        .get-directions__steps {
            margin-left: 5px;

            .get-directions__header {
                width: 50%;
                margin: 20px auto;
                text-align: center;
            }

            li {
                display: flex;
                margin-bottom: 25px;
                text-transform: capitalize;

                .get-directions__instruction {
                    width: 200px;
                    margin-right: 20px;
                }

                .get-directions__address {
                    width: calc(100% - 270px);
                    line-height: 28px;
                }
            }

            svg {
                width: 30px;
                height: 30px;
                margin-right: 20px;
            }
        }

        .fieldgroup__container {
            width: 100%;
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;

            .fieldgroup__field {
                width: 65%;
            }

            .dialog__container-btn {
                width: 30%;
                margin-top: 0px;

                &:disabled {
                    background: $cancelBGHover;
                }
            }
        }
    }

    .get-directions__success {
        height: auto;
    }

    .report-information__footer {
        display: flex;
        justify-content: space-between;

        button {
            width: 48%;

            &.cancel, &.Mui-disabled {
                background: $cancelBG;

                &:hover {
                    background: $cancelBGHover;
                }
            }
        }
    }

    .greattings__message {
        margin-right: auto;
        margin-left: auto;
        width: 60%;
        margin-top: 3%;
        font-family: "Gotham HTF", sans-serif;
        font-size: 22px;
        font-weight: 600;
        color: $grey;
    }
}

@media print {
    .get-directions__map {
        display: none;
    }

    .MuiPaper-root {
        background-color: white;
        height: 100%;
        width: 100%;
        position: fixed;
        top: 0;
        left: 0;
        margin: 0;
        padding: 15px;
        font-size: 5px;
        line-height: 5px;
        z-index: 9999999;
        overflow-wrap: break-word;
    }

    .ProviderSearch__dialog .MuiDialogContent-root {
        margin-top: 0px;

        .get-directions__content {
            overflow-wrap: break-word;
            padding: 10px;
            font-size: 10px;
            line-height: 10px;

            svg {
                width: 15px;
                height: 15px;
                margin-right: 10px;
            }

            .fieldgroup__container {
                display: none;
            }

            .get-directions__steps {
                .get-directions__header {
                    margin-right: 0px;
                    margin-left: 0px;
                    text-align: initial;
                    font-size: 5px;

                    h2 {
                        font-size: 20px;
                    }
                }
            }
        }
    }

    .ProviderSearch__dialog .MuiDialogTitle-root {
        .dialog__header {
            .dialog__header-title {
                font-size: 30px;
            }

            .dialog__header-btn {
                display: none;
            }
        }
    }

    .ProviderSearch__dialog .MuiDialogContent-root .report-information__footer {
        display: none !important;
    }
}

@media (max-width: 1024px) {
    .ProviderSearch__dialog {
        .MuiDialogTitle-root {
            .dialog__header {
                .dialog__header-title {
                    font-size: 22px;
                    line-height: 26px;
                    padding-right: 10px;
                }
            }
        }

        .MuiDialogContent-root {
            .get-directions__content {
                .fieldgroup__container {
                    flex-direction: column;

                    .fieldgroup__field {
                        width: 100%;
                        margin-bottom: 15px;
                    }

                    .dialog__container-btn {
                        width: 100%;
                    }
                }

                .get-directions__steps {
                    margin-left: 0;

                    .get-directions__header {
                        width: 100%;

                        h2 {
                            font-size: 22px;
                        }
                    }

                    ul {
                        margin-left: 0;
                    }

                    li {
                        flex-wrap: wrap;

                        .get-directions__address {
                            width: 100%;
                            padding-left: 50px;
                        }
                    }

                    svg {
                        margin-top: 5px;
                    }
                }
            }

            .report-information__foote {
                flex-direction: column-reverse;

                button {
                    width: 100%;
                }
            }
        }
    }
}
