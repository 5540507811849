%base-alert-message {
  position: fixed;
  top: 20px;
  z-index: 10000;
  margin: 0 auto;
  width: 500px;
  left: calc(50vw + 420px);
}

.alert-messages {
  @extend %base-alert-message;
  .MuiAlert-message {
    font: normal normal bold 14px/26px Raleway, sans-serif;
    color: #252829;

    .MuiAlertTitle-root {
      font-size: 22px;
      text-transform: capitalize;
    }
  }

  &-success {
    @extend %base-alert-message;
    background: #e4f2d9 0% 0% no-repeat padding-box !important;
    border: 1px solid #78bd42 !important;
    border-radius: 5px !important;
    opacity: 1 !important;
    .MuiAlert-message {
      font: normal normal normal 14px/24px Arial;
      color: #252829 !important;
      .MuiAlertTitle-root {
        font: normal normal bold 20px/22px Arial;
        text-transform: capitalize;
        color: #78bd42 !important;
      }
    }
  }

  &-error {
    @extend %base-alert-message;
    background: #fcdcd3 0% 0% no-repeat padding-box !important;
    border: 1px solid #f05022 !important;
    border-radius: 5px !important;
    opacity: 1 !important;
    .MuiAlert-message {
      font: normal normal normal 14px/24px Arial;
      color: #252829 !important;
      .MuiAlertTitle-root {
        font: normal normal bold 20px/22px Arial;
        text-transform: capitalize;
        color: #f05022 !important;
      }
    }
  }
}

@media (max-width: 1024px) {
  .alert-messages,
  .alert-messages-success,
  .alert-messages-error {
    width: 100%;
    left: 0;
  }
}
