$white: #fff;
$shadow: #00000029;
$darkBlue: #066087;

.providertypes.second-tab {
    width: calc(100% - 100px);
    display: flex;
    justify-content: space-between;
    box-sizing: border-box;
    max-width: 1320px;
    padding-right: 50px;
    padding-left: 50px;
    background-color: $white;
    box-shadow: 0px 3px 6px $shadow;
    margin: 0px auto 40px;
    flex-direction: column;
    padding-top: 47px;

    .fieldgroup__submit {
        width: 100%;
        background: #1b54c2;
        border-radius: 0px;
        color: #fff;
        height: 60px;
        font-size: 28px;
        font-family: "Gotham HTF", sans-serif;
        text-transform: capitalize;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-bottom: 50px;
        font-weight: bold;

        &:hover {
            background: #1544a3;
        }
    }

    .fieldgroup__submit[disabled],
    .fieldgroup__submit.disabled {
        background-color: #767679;
        color: #fff;
        pointer-events: none;
    }

    .fieldgroup__clear {
        font: normal normal 600 28px/33px Raleway, sans-serif;
        color: #0376a8;
        background: none;
        width: 190px;
        text-transform: inherit;
        text-decoration: underline;
        box-shadow: none;
        align-self: flex-end;
        text-align: right;
        padding: 0;
        margin: 50px 0;
        
        &:hover,
        &:active,
        &:focus {
            background: none;
            color: $darkBlue;
            text-decoration: underline;
            box-shadow: none;
        }

        &:disabled,
        .disabled {
            color: #767676;
            pointer-events: none;
            cursor: default;
        }
    }

    .disclosure-content {
        background-color: #e7f5f6;
        border: 1px solid #0f99a8;
        border-radius: 3px;
    }
}

@media (max-width: 1024px) {
    .providertypes.second-tab {
        padding: 20px;
        padding-top: 47px;
        box-shadow: none;
        margin: 0 auto;
        width: 100%;
        
        .fieldgroup__clear {
            align-self: center;
            text-align: center;
            margin: 0 0 25px;
        }
    }
}
