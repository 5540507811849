$blue: #1b54c2;
$darkBlue: #1544a3;
$white: #fff;

.help-container {
    background: #f8f8f8 0% 0% no-repeat padding-box;
}

.help_header__div {
    top: 0px;
    left: 0px;
    padding-right: 45px;
    padding-left: 45px;
    padding-top: 90px;
    max-width: 1320px;
    margin: auto;
    opacity: 1;
    width: 100%;

    h2 {
        padding-bottom: 20px;
        font: normal normal 600 45px/36px "franklin-gothic-condensed", sans-serif;
        text-transform: uppercase;
    }

    h4 {
        margin-top: 40px;
        margin-bottom: 0;
        font: normal normal 600 24px/28px "franklin-gothic-condensed", sans-serif;
        color: #000;
    }

    .help-subtitle_underline {
        width: 157px;
        margin-left: -15px;
    }
    
    p {
        margin-bottom: 0;
    }

    p, li, .donwload-link__link {
        font: normal normal 400 24px/30px "franklin-gothic-urw", sans-serif;
    }

    .buttonHelp__back {
        width: 100%;
        display: block;
        text-align: center;
        background: $blue;
        color: $white;
        height: 60px;
        font: normal normal 700 28px/30px "franklin-gothic-urw", sans-serif;
        line-height: 60px;
        text-transform: capitalize;
        margin-top: 30px;

        &:hover {
            background: $darkBlue;
            color: $white;
        }
    }

    .hyperlink {
        color: $blue;
        text-decoration-color: currentcolor;
    }

    .donwload-link {
        display: flex;
        flex-direction: row;
        margin-bottom: 40px;

        .icon {
            height: 35px;
        }

        .donwload-link__link {
            margin: 5px 0 0 20px;
        }
    }
}

@media (max-width: 500px) {
    .help_header__div {
        .buttonHelp__back {
            line-height: unset;
            height: unset;
        }
    }
}