$backgroundColor: #0376a8;

.PCP_details__spinner {
    display: flex;
    justify-content: center;
    height: 900px;
    align-items: center;
}

.PCP_details__locations_and_below {
    background-color: #ffff !important;
    padding-top: 50px;
    padding-bottom: 50px;
}