.error-wrapper {
    box-sizing: border-box;
    max-width: 1155px;
    margin: 40px auto 0;
    font-size: 17px;

    .error-title {
        font: normal normal 700 28px/29px "franklin-gothic-urw", sans-serif;
        margin-bottom: 0;
    }

    .error-content {
        font-size: 18px;
    }

    button {
        vertical-align: middle;
        margin: 0 0 1rem;
        padding: .8125rem 1.5625rem;
        border: 1px solid transparent;
        border-radius: 0;
        transition: background-color .25s ease-out, color .25s ease-out;
        font-family: inherit;
        font-size: 1.375rem;
        line-height: 1;
        text-align: center;
        cursor: pointer;
        background-color: #1b54c2;
        color: #fefefe;

        &:hover {
            background-color: #0065f2;
        }
    }
}

