$white: #fff;
$blue: #1b54c2;
$darkBlue: #1544a3;
$grey: #00000029;

.filters-container {
    width: 100%;
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    margin-top: 50px;
    padding-bottom: 60px;

    .fieldgroup__submit {
        width: 100%;
        background: $blue;
        border-radius: 0px !important;
        color: $white;
        height: 60px;
        font: normal normal 700 28px "franklin-gothic-urw", sans-serif;
        text-transform: inherit;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-bottom: 40px;
    font-weight: bold;

        &:not([disabled]):hover,
        &:not([disabled]):focus,
        &:not([disabled]):active {
            background: $darkBlue;
            color: $white;
        }

        &[disabled],
        &.disabled {
            background-color: #767679;
            border-radius: 20px;
            color: $white;
            pointer-events: none;
        }

        .spinner__container {
            width: 25px;
            height: 25px;
            margin-left: 10px;

            .loader {
                border: 4px solid $white;
                border-top: 4px solid $blue;
            }
        }
    }

    .fieldgroup__clear {
        font: normal normal 400 28px/33px "franklin-gothic-urw", sans-serif;
        color: $blue;
        background: none;
        width: 190px;
        text-transform: inherit;
        text-decoration: underline;
        box-shadow: none;
        align-self: flex-end;
        text-align: right;
        padding: 0;

        &:hover,
        &:active,
        &:focus {
            background: none;
            color: $darkBlue;
            text-decoration: underline;
            box-shadow: none;
        }

        &:disabled,
        .disabled {
            color: #767676;
            pointer-events: none;
            cursor: default;
        }
    }

    .filters-container__text {
        font-size: 26px;
        font-family: "franklin-gothic-urw", sans-serif;
        line-height: 40px;
        margin-bottom: 50px;
    }

    hr {
        border: 1px solid $grey;
        width: 100%;
        max-width: 100%;
        margin-top: 60px;
    }
}

@media (max-width: 1024px) {
    .filters-container {
        margin-top: 0;
        padding-bottom: 0px;

        .fieldgroup__submit {
            height: auto;
            font-size: 24px;
            padding: 10px 20px;
        }

        .fieldgroup__clear {
            margin-top: 0;
            margin-left: auto;
            margin-right: auto;
            font-weight: 700;
        }
    }

    .filters-container__text {
        margin-bottom: 20px;
    }
}