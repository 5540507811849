$backColorBase: #ffffff;
$colorBase: #000000;
$checkColor: #76bc21;
$exclamationColor: #f9a127;
$tackBackground: #dedede;
$thumbColor: #0b3a5d;
$closeButtonColor: #1b54c2;

.label__base {
  text-align: left;
  font-weight: 600;
  font-size: 24px;
  font-family: "Gotham HTF", sans-serif;
  letter-spacing: 0px;
  color: $colorBase;
}

.text__base {
  text-align: left;
  font-size: 24px;
  letter-spacing: 0px;
  color: $colorBase;
}

hr.MuiDivider-root {
  margin-top: 20px;
  border-color: $tackBackground;
}

.locationsPreview__container {
  height: 100%;
  width: 100%;
  padding: 25px;
  background-color: $backColorBase;
  border-radius: 50px;

  .header__container {
    display: flex;
    align-items: center;
  }
  .icon__container {
    display: flex;
    align-items: center;
    justify-content: center;
    color: $closeButtonColor;

    .buttonIcon {
      color: inherit;
      cursor: pointer;
      background: transparent;
      border: none;
    }

    &.locationIcon__container {
      background: transparent;
      width: 84px;
      border-radius: 10px;
      color: $colorBase;

      .location__length {
        font-size: 30px;
        font-weight: bold;
        margin-left: 10px;
      }

      &:hover {
        cursor: auto;
      }
    }
  }
  .title__container {
    flex: 1;
    text-align: left;
    font-size: 36px;
    font-weight: 600;
    letter-spacing: 0px;
    color: $colorBase;
    text-transform: capitalize;
    opacity: 1;
  }

  .locationsList__container {
    overflow-y: auto;
    max-height: 350px;
    margin-top: 15px;

    &::-webkit-scrollbar {
      width: 0.4em;
    }

    &::-webkit-scrollbar-track {
      background-color: $tackBackground;
      border-radius: 10px;
    }

    &::-webkit-scrollbar-thumb {
      background-color: $thumbColor;
      outline: 1px solid $thumbColor;
      border-radius: 10px;
    }

    .locationsList {
      list-style-type: none;
      margin: 0px 85px 0px 85px;
      padding: 0;

      .locationDetail__container {
        .locationName__container {
          @extend .label__base;
        }

        .locationAddress__container {
          .locationAddress {
            @extend .text__base;
          }
        }

        .locationNumber__container {
          .locationNumber {
            @extend .text__base;
          }
        }
      }
    }
  }
}

@media (max-width: 575px) {
  .locationsPreview__container .locationsList__container .locationsList {
    margin: 0px 15px 0px 5px;
  }
}