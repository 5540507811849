$blueTxt: #082941;
$blueBtns: #1b54c2;
$white: #fff;
$darkBlue: #1544a3;

// For the new MUI component
.ProviderSearch__dialog {
    margin-right: auto;
    margin-left: auto;

    // we need this !important to make sure that the dialog will be displayed over the header
    z-index: 9999 !important;

    .css-yiavyu-MuiBackdrop-root-MuiDialog-backdrop {
        opacity: 0 !important;
    }

    .MuiPaper-root {
        border: 2px solid $blueBtns;
        border-radius: 0;
        max-width: 900px;
    }

    .MuiDialogTitle-root {
        background: $blueBtns;
        height: 85px;

        .dialog__header {
            display: flex;
            align-items: center;
            justify-content: space-between;

            .dialog__header-title {
                font: normal normal 700 34px/45px "franklin-gothic-urw", sans-serif;
                letter-spacing: 0px;
                color: $white;
            }

            .dialog__header-btn {
                font-family: Raleway, sans-serif;
                background: none;
                font-size: 28px;
                line-height: 28px;
                color: $white;
                cursor: pointer;
                font-weight: 600;
                border: none;
            }
        }
    }

    .MuiDialogContent-root {
        font-size: 22px;
        line-height: 33px;
        font-family: "Gotham HTF", sans-serif;
        margin-top: 30px;
        width: 850px;

        .dialog__container-btn {
            box-shadow: none;
            background: $blueBtns;
            color: $white;
            font-size: 28px;
            line-height: 33px;
            font-family: "Gotham HTF", sans-serif;
            text-transform: capitalize;
            width: 100%;
            margin-left: 0;
            height: 60px;
            border-radius: 0;
            margin-top: 20px;

            &:hover {
                background-color: $darkBlue;
            }
        }
    }
}

@media print {
    .ProviderSearch__dialog {
        .MuiPaper-root {
            border: none;
        }
    }
}

@media (max-width: 1024px) {
    .ProviderSearch__dialog .MuiDialogContent-root {
        width: 100%;
    }
}
