@import "https://fonts.cdnfonts.com/css/gotham";

$bgcolor: #fefefe;
$blue: #0376a8;
$grey: #333;
$shadow: #00000029;
$dropdownBGColor: #eee;
$hover: #f4f4f4;

html {
    scroll-behavior: smooth;
}

body {
    background: $bgcolor;
}

.font-cndm {
    font-family: "franklin-gothic-condensed", sans-serif;
    font-weight: 600;
    font-style: normal;
}

.font-heavy {
    font-family: "franklin-gothic-urw", sans-serif;
    font-weight: 900;
    font-style: normal;
}

.font-demi {
    font-family: "franklin-gothic-urw", sans-serif;
    font-weight: 700;
    font-style: normal; 
}

.font-cond-demi {
    font-family: "franklin-gothic-urw-cond", sans-serif;
    font-weight: 700;
    font-style: normal; 
}

.font-medium {
    font-family: "franklin-gothic-urw", sans-serif;
    font-weight: 500;
    font-style: normal
}

.font-book {
    font-family: "franklin-gothic-urw", sans-serif;
    font-weight: 400;
    font-style: normal;
}

// dropdown list is displayed at the DOM root, so this styles would be general for every dropdown list
.MuiAutocomplete-noOptions {
    font-size: 26px !important;
}

.MuiAutocomplete-listbox {
    font-size: 26px !important;
    font-family: "Gotham HTF", sans-serif;
    padding: 5px 0;
    box-shadow: 0px 3px 6px rgb(0 0 0 / 30%);
    color: #000;
    border: 1px solid $shadow;
}

.MuiAutocomplete-option {
    background: #fff;
}

.MuiAutocomplete-option:hover {
    background: $hover !important;
}

// end of dropdown list styles

// tooltip is is displayed at the DOM root, so this styles would be general for every tooltip on the project

.MuiTooltip-tooltip {
    font-family: "franklin-gothic-urw", sans-serif !important;
    font-style: normal;
    font-size: 26px !important;
    font-weight: 200 !important;
    line-height: 33px !important;
    padding: 38px 45px !important;
    background-color: #06385D !important;
    max-width: 729px !important;
}

.MuiTooltip-arrow {
    color: #06385D !important;
}

// end of tooltip styles

// autocomplete items list is also displayed at the DOM root
.pac-item, .pac-item-query, .MuiMenuItem-root {
    font-size: 26px !important;
    font-family: "Gotham HTF", sans-serif;
    padding: 5px 0;
    line-height: 40px;
    border: none;
}

.pac-container {
    z-index: 10000 !important;
}

.pac-item-query {
    margin-right: 10px;
}

.pac-item {
    &:hover {
        background: $dropdownBGColor;
    }
}

// end of autocomplete styles

.initialLoader__container {
    background: #f8f8f8;
    top: 0;
    left: 0;
    right: 0;
    height: 234px;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 99999;
}

.fieldgroup__field-input, 
.MuiInputBase-input {
    color: #000 !important;
}

@media (max-width: 1024px) {
    .initialLoader__container {
        height: 73px;
    }
}

@media print {
    .findAProviderHeader__help,.providertypes,.providertype-disclaimer,.filters-search__container,.resultActions__OuterContainer,.providerResult__pager,.back-to-provider__div,.mapCol .hyperlink {
        display: none !important;
    }
}
