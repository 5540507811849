$ghostWhite: #f5f5f8;
$black: black;
$white: #fff;
$blueShadow: rgb(0 123 255 / 25%);
$borderColor: #fbfbff1f;
$blueTxt: #0376a8;

.back-to-provider__div {
    background: $ghostWhite;
    font-size: 22px;
    font-family: "Gotham HTF", sans-serif;
    width: 100%;
    height: 80px;
    border-bottom-style: groove;
    border-bottom-color: $borderColor;
    display: flex;

    .back-to-provider__container {
        padding-left: 50px;
        padding-right: 50px;
        margin: 10px auto;
        opacity: 1;
        display: flex;
        align-items: center;
        width: 100%;
        max-width: 1320px;

        .back-to-provider__button {
            font: normal normal 400 22px/25px "franklin-gothic-urw", sans-serif;
            letter-spacing: 0px;
            color: $black;
            opacity: 1;
        }

        .back-to-provider__icons-data {
            display: flex;
            align-items: center;
            margin-right: 0;
            margin-left: auto;

            &:hover {
                color: $blueTxt;
            }

            button {
                width: 110px;
                text-transform: capitalize;
                font-weight: 400;
                margin-right: 15px;
                color: $black;
                font: normal normal 400 22px/25px "franklin-gothic-urw", sans-serif;
                letter-spacing: 0.26px;
                height: 80px;

                svg {
                    height: 20px;
                }

                &:hover {
                    background: none;
                    color: $blueTxt;

                    svg path {
                        fill: $blueTxt;
                    }
                }

                .MuiTouchRipple-root {
                    display: none;
                }
            }

            .back-to-provider__link {
                display: flex;
                align-items: center;
                padding: 0.5rem 1rem;
                font-size: 22px;
                font-family: "Gotham HTF", sans-serif;
                letter-spacing: 0.26px;
                color: $black;

                &:focus {
                    outline-color: $white;
                }

                &:hover {
                    text-decoration: none;
                    color: $blueTxt;

                    svg path {
                        fill: $blueTxt;
                    }
                }

                svg {
                    fill: $black;
                    margin-right: 10px;
                    width: 20px;
                    height: 20px;
                }
            }
        }
    }
}

@media print {
    .backToSearchResults, .returnButton, .provider-profile__icons-data {
        display: none;
    }
}

@media (max-width: 1024px) {
    .back-to-provider__div {
        height: auto;

        .back-to-provider__container {
            padding: 0;
            flex-direction: column;
            margin-bottom: 0;

            .back-to-provider__button {
                width: 100%;
                padding: 10px 20px;
            }

            .back-to-provider__icons-data {
                width: 100%;
                height: 44px;
                border-top-style: groove;
                border-top-color: $borderColor;

                button {
                    height: 49px;
                }
            }
        }
    }
}
