.wrapper {
    width: 100%;
    background: #3172a3;
    margin-bottom: 25px;
}

.banner {
    width: 1519px;
    height: auto;
}

@media (max-width: 1024px) {
    .wrapper {
        display: inherit;
    }
}