$backgroundColor: white;
$headerFontColor: #000;
$accordionTitleColor: #fff;
$darkBlue: #066087;
$blue: #1b54c2;

.location__div {
    background: $backgroundColor;
    width: 100%;

    .location__container {
        margin: auto;
        padding: 50px;
        max-width: 1320px;
    }

    .location__hmo__disclaimer {
        font: normal normal 400 23px "franklin-gothic-urw", sans-serif;

        b {
            color: $blue;
        }
    }

    .location_title {
        text-align: left;
        padding-top: 34px;
        font: normal normal 600 40px/25px "franklin-gothic-condensed", sans-serif;
        letter-spacing: 0px;
        color: $headerFontColor;
        opacity: 1;
    }

    .location_details__div {
        padding-bottom: 135px;
        width: 100%;
        
        .marker {
            svg {
                position: relative;
                display: inline-block;
                width: 26px !important;
                height: 36px !important;
                vertical-align: text-top;
                margin-left: 10px;

                text {
                    fill: $blue;
                    font: normal normal 400 28px/16px "franklin-gothic-urw", sans-serif;
                    font-weight: bold;
                    font-size: 32px;
                    text-align: left;
                    letter-spacing: 0.31px;
                    transform: scale(10);
                    transform-origin: center;
                }
            }
        }
    }
    
    .location_expand__btn__div{
        position: relative;
        height: 24px;
        margin-bottom: 30px;

        .location_expand__btn {
            font: normal normal 400 24px/5px "franklin-gothic-urw", sans-serif;
            color: #1b54c2;
            background: none;
            text-transform: inherit;
            text-decoration: underline;
            box-shadow: none;
            right: 0;
            position: absolute;

            &:hover {
                background: none;
                color: $darkBlue;
                text-decoration: underline;
                box-shadow: none;
            }
        }
    }
}

.disclosure-content {
    margin: 30px 0px;
}

@media (max-width: 1366px) {
    .location__div {
        .location__container {
            padding: 50px 20px;
        }
    }
}

@media (max-width: 1024px) {
    .location__div {
        .location__container {
            padding: 10px 20px;
        }

        .location_title {
            font-size: 30px;
            line-height: 33px;
        }

        .location_details__div {
            padding-bottom: 0px;

            .accordion_background {
                margin-bottom: 30px;
            }
        }
    }
}

@media (max-width: 575px) {
    .disclosure-content {
        margin: 30px 0px 10px 0;
        padding: 15px 20px;
    }
}