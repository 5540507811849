$bgColor: #e7f5f6;
$blue: #0f99a8;
$white: #fff;

.ppodisclaimer__container {
    background-color: $bgColor;
    border: 1px solid $blue;
    border-radius: 5px;
    padding: 30px 50px;
    display: flex;
    flex-direction: column;
    margin-bottom: 50px;

    .ppodisclaimer__title {
        display: flex;
        align-items: center;
        margin-bottom: 30px;

        .ppodisclaimer__title-icon {
            width: 50px;
            height: 50px;
            color: $white;
            display: flex;
            justify-content: center;
            align-items: center;
            background: $blue;
            border-radius: 25px;
            margin-right: 15px;
            font-family: Raleway, sans-serif;
        }

        .ppodisclaimer__title-text {
            font: normal normal 500 22px/28px "franklin-gothic-urw", sans-serif;
            color: $blue;
        }
    }

    .ppodisclaimer__content {
        font: normal normal 400 20px/28px "franklin-gothic-urw", sans-serif;

        .hyperlink {
            color: $blue;
            text-decoration: underline;
        }
    }
}

.vd_disclaimer__container {
    background-color: $bgColor;
    border: 1px solid $blue;
    border-radius: 5px;
    padding: 30px 50px;
    display: flex;
    flex-direction: column;
    margin-bottom: 50px;

    .vd_disclaimer__title {
        display: flex;
        align-items: center;
        margin-bottom: 30px;

        .vd_disclaimer__title-icon {
            width: 50px;
            height: 50px;
            color: $white;
            display: flex;
            justify-content: center;
            align-items: center;
            background: $blue;
            border-radius: 25px;
            font-weight: 700;
            font-size: 24px;
            margin-right: 15px;
            font-family: Raleway, sans-serif;
        }

        .vd_disclaimer__title-text {
            font: normal normal 500 22px/28px "franklin-gothic-urw", sans-serif;
            color: $blue;
        }
    }

    .vd_disclaimer__content {
        font: normal normal 400 20px/28px "franklin-gothic-urw", sans-serif;

        .hyperlink {
            color: $blue;
            text-decoration: underline;
        }
    }
}
