$borderColor: #F89C1D;

.disclosure-content {
    background: #FFF3CD;
    border: 2px solid $borderColor;
    padding: 20px 30px;
    margin-bottom: 44px;
    font: normal normal 400 22px/28px "franklin-gothic-urw", sans-serif;
    text-transform: none;

    p {
        margin: 0;
        line-height: 1.2;
    }
}

/* Custom styles */
.location-disclosure {
    span {
        display: block;
        margin-top: 16px;
        line-height: 1.2;
    }
}

.no-background {
    background: transparent;
    border-color: transparent;
    padding: 20px 0 20px 0;
}