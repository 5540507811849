%title_base {
    text-align: left;
    font: normal normal 700 24px "franklin-gothic-urw", sans-serif;
    letter-spacing: 0px;
    color: #000000;
}

%text_base {
    text-align: left;
    font: normal normal 400 24px "franklin-gothic-urw", sans-serif;
    letter-spacing: 0px;
    color: #000000;
}

.medical_info__container {
    display: flex;
    justify-content: space-between;
    margin-bottom: 14px;

    .wrapper-left {
        width: 40%;
    }

    .provider__container,
    .pcp__container div{
        margin-bottom: 12px;

        span:nth-child(1) {
            @extend %title_base;
        }

        span:nth-child(2) {
            @extend %text_base;
        }
    }

    .plans_accordion__container {
        flex: 1;
        margin-left: 30px;
    }

    .md-plans__container {
        .accordion_background_secondary {
            background: transparent;
            justify-content: flex-start;
        }

        .accordion_icon {
            margin-left: 35px;
        }

        .accordion_content {
            padding-top: 0px;
        }
    }

    .plans__container {
        span:nth-child(1) {
            @extend %title_base;
        }

        .pbp-list__container {
            @extend %text_base;

            p {
                margin-bottom: 0px;
                line-height: 1.6;
            }
        }
    }
}

.accepting-new-patients__container {
    display: flex;
    flex-direction: row;

    span:nth-child(2) {
        margin-left: 21px;
        font-size: 24px;
    }
}

@media (max-width: 765px) {
    .medical_info__container {
        display: unset;

        .plans_accordion__container {
            margin-left: 0px;

            .plans__container {
                .md-plans__container {
                    margin: 0px !important;

                    .accordion_background_secondary {
                        padding: 33px 0px 13px 0px;
                    }

                    .accordion_content {
                        padding: 0px 0px 30px;
                    }
                }
            }
        }
        .plans__container .pbp-list__container p {
            font: normal normal 400 21px/26px "franklin-gothic-urw", sans-serif;;
            margin-bottom: 12px;
        }
    }
}

@media (max-width: 575px) {
    .medical_info__container{
        .wrapper-left {
            width: 100%;
            margin-bottom: 20px;
        }

        .provider__container,
        .pcp__container {
            display: flex;
            flex-direction: column;
        }

        .plans_accordion__container {
            margin-bottom: 15px;

            .plans__container {
                .md-plans__container{
                    .accordion_background_secondary {
                        padding: 5px 0 5px 0;
                    }

                    .accordion_icon {
                        margin-left: 20px;
                    }

                    .accordion_content {
                        padding: 0px 0px 5px;
                    }
                }
            }
        }
    }
}