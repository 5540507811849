.spinner-results {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 700px;
    width: 100%;
}

.home-tabs {
    width: calc(100% - 100px);
    display: flex;
    justify-content: space-between;
    box-sizing: border-box;
    max-width: 1320px;
    margin: 45px auto 0;
    position: relative;

    &::before {
        content: '';
        height: 110px;
        border: 1px solid #1B54C2;
        position: absolute;
        width: 100%;
        top: -25px;
        background-color: #1B54C2;
    }

    .MuiTabs-root,
    .MuiTabs-scroller,
    .MuiTouchRipple-root {
        overflow: visible !important;
    }

    .MuiTabs-indicator {
        display: none;
    }

    .MuiTabs-flexContainer {
        .MuiButtonBase-root {
            font: normal normal 700 28px/29px "franklin-gothic-urw", sans-serif;
            text-transform: capitalize;
            padding: 20px;
            width: 370px;
            border-top-left-radius: 40px;
            border-top-right-radius: 40px;
            border-bottom-left-radius: 0;
            border-bottom-right-radius: 0;
            background: #3F6DC8;
            color: #ffff;
            position: relative;
            overflow: visible !important;
            max-width: 400px;
            margin-left: 50px;

            &:nth-child(2) {
                margin-left: 18px;
            }

            &:after,
            &:before {
                content: "";
                width: 40px;
                height: 40px;
                border: 13px solid #3F6DC8;
                position: absolute;
                bottom: -14px;
                border-top: 0;
            }

            &:after {
                -moz-border-radius: 0 0 40px 0;
                -webkit-border-radius: 0 0 40px 0;
                border-left: 0;
                border-radius: 0 0 40px 0;
                left: -28px;
            }

            &:before {
                -moz-border-radius: 0 0 0 40px;
                -webkit-border-radius: 0 0 0 40px;
                border-right: 0;
                border-radius: 0 0 0 40px;
                right: -28px;
            }

            &.Mui-focusVisible {
                background: #fff;
            }

            .MuiTouchRipple-root {
                z-index: 1;

                span {
                    display: none;
                }
            }

            &.Mui-selected {
                background: #fff;
                box-sizing: border-box;
                color: #1B54C2;
                z-index: 3;
                border: 1px solid #fff;
                outline: none;

                &:after {
                    border-color: #ffff;
                }

                &:before {
                    border-color: #ffff;
                }


            }

            .MuiTab-wrapper {
                overflow: visible;
                white-space: nowrap;
                text-overflow: ellipsis;
                position: relative;
                z-index: 4;
                @media (max-width: 400px) {
                    width: 100%;
                    margin-left: 0;
                }
            }

            .MuiTab-wrapper span {
                position: relative;
                z-index: 4;
            }
        }
    }
}

@media (max-width: 1330px) {
    .home-tabs .MuiTabs-flexContainer .MuiButtonBase-root {
        width: 300px;
        border-top-left-radius: 20px;
        border-top-right-radius: 20px;
    }
}

@media (max-width: 1024px) {
    .home-tabs {
        width: 100%;
        border-radius: 0px;

        .MuiTabs-flexContainer{
            justify-content: center;

            .MuiButtonBase-root {
                width: calc(40% - 10px);
                box-sizing: border-box;
                margin-left: 25px;
                margin-right: 25px;
            }
        }

        .MuiTabs-root {
            width: 80%;
        }
        
        &:before {
            border-radius: 0px;
        }
    }
}

@media (max-width: 765px) {
    .home-tabs {
        margin: 50px 0 0;

        .MuiTabs-root {
            width: 100%;
        }

        .MuiTabs-flexContainer {
            padding: 0 5%;

            .MuiButtonBase-root {
                flex-grow: 1;
                font-size: 17px;
                padding: 8px;
                margin-left: 20px;
                margin-right: 20px;

                &:before,   
                &:after{
                    height: 30px
                }

                .MuiTouchRipple-root:after,
                .MuiTouchRipple-root:before {
                    display: none;
                }
            }
        }
    }
}