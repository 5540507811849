$backgroundColor: #0376a8;
$providerName: #0376a8;
$iconsContainerBackground: #fafafa;
$borderColor: #dedede;
$borderShadowColor: #0003;

.providerResults {
    width: calc(100% - 100px);
    max-width: 1320px;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin: 70px auto;

    .providerCard__LinkContainer {
        width: 48%;
        max-width: 635px;
        height: auto;
        border: solid 1px $borderColor;
        box-shadow: 1px 1px 5px $borderShadowColor;

        /* This property can be inherited form the father component, for that reason I set it here */
        background-color: #fff;
        margin-bottom: 50px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        color: unset;
    }

    .providerResult__pager {
        width: 100%;
        display: flex;
        flex-flow: row wrap;
        max-width: 1320px;
        padding: 0 25px;
        height: 60px;
        align-content: center;
        justify-content: space-between;
        border: solid 1px #dedede;
        box-shadow: 1px 1px 5px rgb(0 0 0 / 20%);
        background-color: #fff;
        font-size: 24px;

        .providerResult__pager.disabled {
            opacity: 0.5;
            pointer-events: none;
        }

        button {
            border-radius: 50%;
        }

        .pager__information {
            font-family: "Arial", sans-serif;
            font-weight: normal;
            display: flex;
            align-items: center;
        }
    }

    .provierResult__noResult {
        font-size: 28px;
        line-height: 33px;
        font-family: "Gotham HTF", sans-serif;
        letter-spacing: 0px;
    }
}

.providerResults.loading {
    justify-content: center;
}

@media (max-width: 1024px) {
    .providerResults {
        justify-content: center;
        width: unset;
        margin: unset;
        background-color: white;
        padding: 70px 20px 15px;

        .providerCard__LinkContainer {
            width: 100%;
            height: 486px;

            .information__innerContainer {
                .provider__name {
                    font-size: 28px;
                    line-height: 33px;
                    word-break: break-word;
                }

                p {
                    line-height: 24px;
                    font-size: 22px;
                }
            }

            .icons__innerContainer {
                justify-content: center;

                .icon__container {
                    font-size: 18px;
                    margin-left: 12px;
                    margin-right: 12px;
                }
            }
        }

        .providerResult__pager {
            padding: 0;
            display: block;
            position: relative;

            .MuiPagination-root {
                .MuiPagination-ul {
                    position: relative;
                    width: 100%;
                    margin-top: 12px;

                    li:nth-last-child(2) {
                        position: absolute;
                        right: 38px;
                    }

                    li:last-child {
                        position: absolute;
                        right: 0px;
                    }
                }
            }

            .pager__information {
                font-size: 14px;
                width: calc(100% - 152px);
                display: block;
                text-align: center;
                position: absolute;
                left: 76px;
                top: 18px;
            }

            button.MuiPaginationItem-page, .MuiPaginationItem-ellipsis, .mobile__hidden {
                display: none;
            }
        }
    }
}

@media (max-width: 450px) {
    .providerResults
    .providerCard__LinkContainer
    .icons__innerContainer
    .icon__container {
        margin-left: 0;
        margin-right: 0;
    }
}