$blue: #0376a8;
$darkBlue: #066087;

.providertype-disclaimer {
    display: flex;
    align-content: center;
    flex-direction: column;
    align-items: flex-end;
    max-width: 1320px;

    .providertype-disclaimer__text {
        font-size: 26px;
        line-height: 40px;
        font-family: "Gotham HTF", sans-serif;

        .hyperlink {
            color: $blue;
            text-decoration: underline;
        }
    }

    .providertype-disclaimer__btn {
        font: normal normal 400 28px/33px Raleway, sans-serif;
        color: #0376a8;
        background: none;
        text-transform: inherit;
        text-decoration: underline;
        box-shadow: none;

        &:hover {
            background: none;
            color: $darkBlue;
            text-decoration: underline;
            box-shadow: none;
        }
    }
}

@media (max-width: 1024px) {
    .providertype-disclaimer {
        margin: 0px auto;
        width: calc(100% - 40px);
        font-size: 26px;

        .providertype-disclaimer__btn {
            margin-bottom: 50px;
            font-size: 26px;
        }
    }
}
