$white: #fff;
$black: #000;
$blue: #0376a8;
$shadow: #00000029;
$colorDoctor: #0f99a8;
$bgDoctor: #e7f5f6;
$colorFacilities: #78bd42;
$bgFacilities: #f2f8ec;
$colorDental: #f9a127;
$bgDental: #fef5e8;
$colorVision: #f05022;
$bgVision: #feeee9;
$colorServices: $blue;
$bgServices: #e6f1f6;
$placeholder: #a2a2a2;

.chat-view {
    width: calc(100% - 100px);
    display: flex;
    justify-content: space-between;
    box-sizing: border-box;
    max-width: 1320px;
    background-color: $white;
    box-shadow: 0px 3px 6px $shadow;
    margin: 0px auto 40px;
    flex-direction: column;
    padding: 50px 50px 0;
    position: relative;

    form {
        width: 100%;
        margin: 0 auto 50px;
        display: flex;

        .chat-input {
            box-shadow: none;
            background: none;
            font-size: 26px;
            font-family: "Gotham HTF", sans-serif;
            padding: 0 20px;
            width: 100%;
            font-weight: 400;
            height: 58px;
            border: 1px solid #767679;
            border-radius: 20px 0 0 20px;

            &::placeholder {
                color: $placeholder;
            }
        }

        .chat-button {
            border-radius: 0 20px 20px 0;
            width: 20%;
            background: #1b54c2;
            cursor: pointer;
            border: 0;
            color: #fff;
            height: 60px;
            font-size: 28px;
            font-weight: bold;
            padding: 5px 10px;
            display: flex;
            align-items: center;
            justify-content: center;
        }
    }

    .chat-container {
        height: 580px;
        padding: 30px;
        color: #3d3f42;
        outline: none;
        display: flex;
        flex-direction: column;
        overflow-y: auto;

        .chat-item {
            display: flex;
            margin: 5px 0;

            .chat-message {
                padding: 15px 20px;
                border-radius: 12px;
                font-size: 26px;
                max-width: 80%;
            }
        }

        .bot .chat-message {
            background: #00b2e326;
        }

        .user {
            justify-content: end;
        }

        .user .chat-message {
            background: #76bc2145;
        }
    }

    .new-conversation {
        width: 100%;
    }
}