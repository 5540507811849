$backgroundColor: #3568c9;

@media screen and (min-width: 768px) {
    .MuiButtonBase-root.providerResult__goTop {
        background-color: transparent;
        position: fixed;
        width: 150px;
        height: 150px;
        left: 87%;
        bottom: 15px;
        font-size: 30px;
        z-index: 10;
        cursor: pointer;
        display: flex;
        flex-direction: column;
        align-content: center;
        justify-content: center;
        align-items: center;
        color: $backgroundColor;
        font-family: "Raleway", sans-serif;
        text-transform: none !important;
        opacity: 1;
        transition: opacity 0.2s ease-out;

        svg {
            width: 50px;
            height: 50px;
        }

        &:hover,
        &:focus {
            background-color: transparent;
        }
    }
}

@media screen and (max-width: 767px) {
    .MuiButtonBase-root.providerResult__goTop {
        font-family: "Raleway", sans-serif;
        font-size: xx-large;
        text-transform: none !important;
        cursor: pointer;
        width: 100% !important;
        padding-bottom: 50px;
        background-color: #fff;
        svg {
            display: none;
        }

        &:hover,
        &:focus {
            background-color: #fff;
        }
    }

}