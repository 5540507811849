@use "FiltersContainer";

.filters-search__container {
    width: calc(100% - 100px);
    display: flex;
    justify-content: space-between;
    box-sizing: border-box;
    max-width: 1320px;
    flex-direction: column;
    margin: 60px auto;

    .filters-container {
        background-color: #fff;
        box-shadow: 0px 1px 6px 1px rgba(0, 0, 0, 0.16);
        padding: 50px;
        margin-top: 0;

        .filters-container__row {
            display: flex;
            gap: 20px;
            align-items: flex-end;

            .fieldgroup__container {
                &:nth-child(2) {
                    .fieldgroup__placeholder {
                        .tooltip__container {
                            .fieldgroup__placeholder-icon {
                                svg {
                                    display: none;
                                }
                            }
                        }
                    }
                }

                &:nth-child(3) {
                    .fieldgroup__placeholder {
                        .tooltip__container {
                            .fieldgroup__placeholder-icon {
                                svg {
                                    display: none;
                                }
                            }
                        }
                    }
                }
            }
        }

        .medical-clear__container {
            display: flex;
            justify-content: space-between;

            .medical__container {
                flex: 1;
                margin-right: 210px;

                &:nth-child(1) {
                    .fieldgroup__container {
                        margin-bottom: 0px;
                    }
                }
            }

            .clear__container {
                display: flex;
            }
        }
    }
}

@media (max-width: 1280px) {
    .filters-search__container {
        .filters-container {
            .filters-container__row {
                flex-direction: column;

                .fieldgroup__container {
                    width: 100%;

                    &:nth-child(4) {
                        width: 100%;
                    }
                }
            }
        }
    }
}

@media (max-width: 1024px) {
    .filters-search__container {
        margin: unset;
        width: unset;
        padding-bottom: 30px;
        background-color: #ffff;

        .filters-search__title {
            font-size: 30px;
            line-height: 33px;
            padding: 0 40px;
        }

        .filters-container {
            padding: 20px 40px;
            background: none;
            box-shadow: none;
            
            .medical-clear__container {
                display: block;

                .medical__container {
                    margin-right: unset;
                    padding-bottom: 30px;
                }

                .clear__container {
                    display: flex;
                }
            }
        }
    }
}