$providerName: #1b54c2;
$borderColor: #dedede;
$borderShadowColor: #0003;
$colorBase: #ffffff;

.doctorLink__Container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 25px;
  padding-bottom: 0;
  color: inherit;

  &:hover {
    color: inherit;
  }
}

.information__innerContainer {
  width: 100%;
  flex: 1;

  p {
    margin-bottom: 10px;
    font: normal normal 400 26px "franklin-gothic-urw", sans-serif;
    line-height: 30px;

    strong {
      font-family: "franklin-gothic-urw", sans-serif;
    }
  }

  .provider__name {
    display: inline-block;
    margin-bottom: 20px;
    color: $providerName;
    text-decoration: underline;
    font: normal normal 700 36px/42px "franklin-gothic-urw", sans-serif;
  }

  .provider__secLink {
    display: inline-block;
    margin-bottom: 0px;
    color: black;
    text-decoration: underline;
    font: normal normal 700 26px/26px "franklin-gothic-urw", sans-serif;
  }

  .innerProvider {
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    .provider__name {
      display: inline-block;
      margin-bottom: 20px;
      color: $providerName;
      text-decoration: underline;
      font: normal normal 700 36px/42px "franklin-gothic-urw", sans-serif;
    }
  }

  .provider__information {
    width: 100%;
    height: auto;
    margin-bottom: 20px;
  }

  .provider__labels {
    .check__icon {
      color: #76bc21;
      margin-right: 5px;
      margin-top: 2px;
    }

    .exclamation__icon {
      color: #f9a127;
      margin-right: 5px;
    }

    .icon__container {
      display: flex;
      flex-direction: row;
    }
  }

  &:hover {
    cursor: pointer;
  }
}

.provider-badge__container{
  .provider__badge {
    display: inline-block;
    width: 92px;
    height: 118px;

    svg {
      width: 100%;
      height: 100%;
    }
  }
}

@media (max-width: 1024px) {
  .information__innerContainer {
    .provider__name {
      font-size: 28px;
      line-height: 33px;
    }

    p {
      line-height: 24px;
      font-size: 22px;
    }
  }
}
