$blue: #1b54c2;

.accordion-content__container {
    display: flex;
    justify-content: space-between;
    margin-bottom: 30px;

    .accordion-content__miles {
        font-size: 18px;
        font-weight: 400;
        text-align: center;
    }

    .icon__container {
        strong {
            font-size: 28px;
            display: block;
        }
    }

    .location-details__container {
        width: 100%;

        .details__container {
            display: flex;
            justify-content: space-between;
            
            .directions__container {
                display: inherit;
                flex-direction: column;
                flex: 1;

                span {
                    margin-bottom: 0.5rem;
                }
            }

            .icons__container {
                display: flex;
                font-family: "franklin-gothic-urw";
                font-weight: 500px;

                .icon__container {
                    display: flex;
                    flex-direction: column;
                    color: $blue;

                    .icon__text__container {
                        display: flex;
                        justify-content: center;
                        align-items: center;

                        .text__next_icon {
                            font-size: 29px;
                            font-weight: 500;
                            margin-bottom: 0px;
                        }

                        svg {
                            width: 30px;
                            height: 30px;
                            fill: $blue;
                            margin-right: 5px;
                        }

                        .tooltip-icon svg {
                            margin-top: -7px;
                            
                            path {
                                fill: $blue;
                            }
                        }
                    }

                    &:nth-child(1) {
                        margin-right: 8px;
                    }

                    &:nth-child(2) {
                        margin-left: 12px;
                    }

                    .icon_description {
                        font-size: 27px;
                    }
                }
            }
        }

        .medical-group_expand__wrapper {
            float: right;
            margin-top: -30px;

            .medical-group_expand__btn {
                font: normal normal 400 24px "franklin-gothic-urw", sans-serif;
                text-transform: none;
                text-decoration: underline;
                color: $blue;
            }
        }
    }

    .service_accordion__container {
        display: flex;
        margin-top: 30px;

        .service-list__container {
            margin: 0px;
        }

        .accordion_background_secondary {
            background: transparent;
            justify-content: start;
            padding-left: 0px;
        }

        .accordion_content {
            padding-top: 0px;
            font-size: 26px;
            font-weight: normal;
        }

        .accordion_header {
            margin-right: 20px;
        }
    }
}

.md-accordion__container {
    margin-bottom: 30px;
    box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.1607843137);
}

@media (max-width: 575px) {
    .accordion-content__container {
        margin-bottom: unset;

        .location-details__container {
            .details__container {
                display: grid;
    
                .icons__container {
                    padding: 10px 0 10px 0;
                    justify-content: center;
                }
            }

            .medical-group_expand__wrapper {
                margin-bottom: 5px;
                float: none;
                text-align: center;
    
                .medical-group_expand__btn {
                    padding-left: 4px;
                    padding-right: 4px;
                }
            }
        }
    }
}