$cancelBG: #767679;
$cancelBGHover: #545458;

.ProviderSearch__dialog .MuiDialogContent-root {
    .report-information__content {
        width: 100%;
        padding-top: 2px;

        .fieldgroup__field {
            box-shadow: 0px 0px 2px 0px $cancelBG;

            textarea.fieldgroup__field-input {
                border-radius: 0;
                box-shadow: none;
                background: none;
                padding: 10px 15px;
                height: auto;
                font-weight: 400;

                &::placeholder {
                    color: $cancelBG;
                }
            }

            .MuiFormControl-root {
                .MuiInputBase-root {
                    textarea {
                        box-shadow: none;
                        padding: 8px;
                    }

                    textarea:focus {
                        background-color: inherit;
                        border: none;
                    }

                    .Mui-focused {
                        border-color: lawngreen;
                    }
                }

                .MuiInput-root::after {
                    border: none;
                }

                .MuiInput-root::before {
                    border: none;
                }
            }
        }
    }

    .report-information__footer {
        display: flex;
        justify-content: space-between;

        button {
            width: 48%;

            &.cancel, &.Mui-disabled {
                background: $cancelBG;

                &:hover {
                    background: $cancelBGHover;
                }
            }
        }
    }

    .greattings__message {
        margin-right: auto;
        margin-left: auto;
        width: 60%;
        margin-top: 3%;
        font-family: "Gotham HTF", sans-serif;
        font-size: 22px;
        font-weight: 600;
        color: $cancelBG;
    }
}
