$black: #000;
$blue: #1b54c2;
$grey: #767679;
$disabledBackground: #ededed;
$disabledBackgroundField: #f4f4f4;
$placeholder: #a2a2a2;
$red: #eb1923;

.fieldgroup__container {
    margin-bottom: 30px;
    display: flex;
    flex-direction: column;
    width: 100%;

    .fieldgroup__placeholder {
        display: flex;
        align-items: center;

        .fieldgroup__placeholder-title {
            color: $black;
            font: normal normal 700 28px "franklin-gothic-urw", sans-serif;
            letter-spacing: 0.31px;

            .fieldgroup__placeholder-asterisk {
                margin-bottom: 15px;
            }
        }

        .fieldgroup__placeholder-icon {
            margin-left: 5px;

            svg {
                width: 34px;
                height: 34px;

                path {
                    fill: $blue;
                }
            }
        }

        .fieldgroup__help {
            text-decoration: underline;
            text-transform: none;
            background: none;
            box-shadow: none;
            color: $blue;
            font: normal normal 400 28px/33px "franklin-gothic-urw", sans-serif;
            letter-spacing: 0.31px;
            padding: 0;
            margin-left: 10px;
            cursor: pointer;

            &:hover {
                background: none;
            }
        }
    }

    .fieldgroup__field_loading {
        display: flex;
        justify-content: space-between;
        align-content: center;
        align-items: center;

        & .MuiAutocomplete-root, .fieldgroup__field-input {
            margin-top: 4px;
            height: 60px;

            // !important should be used as a last resource.
            // here is needed to avoid to overwrite each one of the syncfusion styles
            border: 1px solid $grey;
            border-radius: 0px !important;
            font-size: 26px;
            font-family: "franklin-gothic-urw", sans-serif;
            font-weight: 400;
            width: 95%;

            &::placeholder {
                color: $placeholder;
                opacity: 1;
            }

            &.disabled {
                opacity: 0.8;
                background: $disabledBackgroundField !important;
            }

            & .MuiAutocomplete-input {
                box-shadow: none;
                background: none;
                border: none;
                font-size: 26px;
                font-family: "franklin-gothic-urw", sans-serif;
                font-weight: 400;
                padding: 0 20px;

                &::placeholder {
                    color: $placeholder;
                    opacity: 1;
                }
            }

            & .MuiOutlinedInput-notchedOutline {
                height: 100%;
                border-style: none;
                border-width: 0px;
            }

            & .MuiFormControl-root-MuiTextField-root {
                height: 100%;
            }

            & .MuiOutlinedInput-root {
                padding: 0px;
                height: 100%;
            }

            .MuiTextField-root {
                height: 60px;
            }
        }
    }

    .fieldgroup__field {
        & .MuiAutocomplete-root, .fieldgroup__field-input {
            margin-top: 4px;
            height: 60px;

            // !important should be used as a last resource.
            // here is needed to avoid to overwrite each one of the syncfusion styles
            border: 1px solid $grey;
            font-size: 26px;
            font-family: "franklin-gothic-urw", sans-serif;
            font-weight: 400;
            background: none;
            border-radius: 0px !important;
            box-shadow: none;

            &::placeholder {
                color: $placeholder;
                opacity: 1;
            }

            &.disabled {
                opacity: 0.8;
                background: $disabledBackgroundField !important;
            }

            & .MuiAutocomplete-input {
                box-shadow: none;
                background: none;
                border: none;
                font-size: 26px;
                font-family: "franklin-gothic-urw", sans-serif;
                font-weight: 400;
                padding: 0 20px;

                &::placeholder {
                    color: $placeholder;
                    opacity: 1;
                }
            }

            & .MuiOutlinedInput-notchedOutline {
                height: 100%;
                border-style: none;
                border-width: 0px;
            }

            & .MuiFormControl-root-MuiTextField-root {
                height: 100%;
            }

            & .MuiOutlinedInput-root {
                padding: 0px;
                height: 100%;
            }
        }

        & .MuiTextField-root {
            height: 100%;
        }

        &.error {
            .fieldgroup__field-input {
                border-color: $red;
            }
        }

        .fieldgroup__field-input {
            width: 100%;
            font-weight: 400;
            padding: 0 20px;
            box-sizing: border-box;

            &:focus {
                outline: none;
            }

            &::placeholder {
                color: $placeholder;
                opacity: 1;
            }
        }
    }

    .error-message {
        color: $red;
        font-size: 22px;
        margin-top: 10px;
    }
}

@media (max-width: 1024px) {
    .fieldgroup__container {
        .fieldgroup__placeholder {
            .fieldgroup__placeholder-title {
                font-size: 22px;
                line-height: 26px;

                .fieldgroup__help {
                    font-size: 22px;
                    line-height: 26px;
                    margin-left: 0;
                    text-align: left;
                    margin-top: 10px;
                    display: block;
                }
            }

            .fieldgroup__placeholder-icon {
                margin-bottom: 4px;
            }
        }

        .fieldgroup__field,
        .fieldgroup__field_loading {
            & .MuiAutocomplete-root, .fieldgroup__field-input {
                font-size: 22px;

                & .MuiAutocomplete-input {
                    font-size: 22px;
                }
            }
        }
    }
    .MuiAutocomplete-option {
        font-size: 22px !important;
    }
}

@media screen and (max-width: 1000px) {
    .fieldgroup__container .fieldgroup__field_loading {
        & .MuiAutocomplete-root, .fieldgroup__field-input {
            width: 85%;
        }
    }
}
